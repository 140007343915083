declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
      danger: true;
      "primary-green": true;
      "product": true;
      modal: true;
    }
  }

export * from "./question";
export * from "./image";
export * from "./ecommerce";
export * from "./video";
export * from "./product";
export * from "./openAnswer";
export * from "./text";
export * from "./shared";
