import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import { Box, BoxProps, TypographyProps } from "@mui/material";
import { useEffect, useState } from "react";
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import { CalendarIcon } from "ui";
import { calendarConfig } from "./calendarConfig";
import { calendarStyles } from "./calendarStyles";
import { SelectField } from "../../../apps/web/src/components/Select";
import { Dropdown } from "../Dropdown";

export const DatePicker = ({
  rootProps,
  defaultValue,
  onChange,
  calendarProps,
  ...rest
}: DatePickerProps) => {
  const [date, setDate] = useState<StateType>(null);

  const handleChange = (value: DateType) => {
    setDate(value);
    onChange(value);
  };

  useEffect(() => {
    if (defaultValue) {
      const { year, month, day } = defaultValue;
      setDate({ year, month, day });
    } else {
      setDate(null);
    }
  }, [defaultValue]);

  const getValue = (date: StateType) => {
    if (!date) return undefined;
    const { year, month, day } = date;
    const monthString = month < 10 ? `0${month}` : month;
    const dayString = day < 10 ? `0${day}` : day;
    return `${monthString}.${dayString}.${year}`;
  };

  return (
    <Box {...rootProps}>
      <Dropdown
        {...rest}
        input={(open) => (
          <SelectField
            open={open}
            value={getValue(date)}
            placeholder="MM.DD.YYYY"
            fieldStyles={{ flex: 1 }}
            icon={
              <CalendarIcon color="var(--mui-palette-input_fields-text-color)" />
            }
          />
        )}
      >
        <Box sx={calendarStyles}>
          <Calendar
            calendarClassName="my_custom_calendar"
            onChange={handleChange}
            value={date as DateType}
            locale={calendarConfig}
            minimumDate={calendarProps?.minimumDate}
            maximumDate={calendarProps?.maximumDate}
          />
        </Box>
      </Dropdown>
    </Box>
  );
};

export type minMaxDateType = {
  year: number;
  month: number;
  day: number;
};

export type DatePickerProps = {
  rootProps?: BoxProps;
  label?: string;
  labelProps?: TypographyProps;
  direction?: "row" | "column";
  hideLabelOnOpen?: boolean;
  defaultValue?: DateType | null;
  calendarProps?: {
    minimumDate?: minMaxDateType;
    maximumDate?: minMaxDateType;
  };
  onChange: (value: DateType) => void;
};
export type DateType = { year: number; month: number; day: number };
type StateType = DateType | null;
