import { Box, TextField } from "@mui/material";
import { Layout } from "../shared/Layout";

type TextProps = {
  simulated?: boolean;
  text: string;
  onContinue: () => void;
  loading: boolean;
  settings?: {
    auto_proceed_timer: number;
    countdown_timer: number;
  };
  disabled?: boolean;
  buttonLabel?: string;
};

export const Text = ({
  disabled,
  onContinue,
  text,
  loading,
  buttonLabel = "Continue",
  settings = {
    auto_proceed_timer: 0,
    countdown_timer: 0,
  },
}: TextProps) => {
  return (
    <Layout
      loading={loading}
      disabled={disabled}
      buttonLabel={buttonLabel}
      onContinue={() => onContinue()}
      auto_proceed_timer={settings.auto_proceed_timer}
      countdown_timer={settings.countdown_timer}
    >
      <Box
        sx={{
          width: "100%",
          px: {
            xs: "24px",
            sm: "24px",
            md: "0px",
          },
          py: {
            xs: "24px",
            sm: "0px",
            md: "0px",
          },
          a: {
            color: "var(--mui-palette-options-active-color)",
          },
          img: {
            maxWidth: "100%",
            height: "auto",
            objectFit: "contain",
          },
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Layout>
  );
};
