import React, { useState } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import Image from "next/image";
import {
  CheckIcon,
  CheckFilledIcon,
  CheckIconCircle,
  CheckFilledIconCircle,
} from "ui";
import { ChoiceType } from "../Question";

interface MultipleImageProps {
  choice: ChoiceType;
  checked?: boolean;
  onChange?: (checked: boolean, answer?: string) => void;
  variant?: "single" | "multiple";
  disabled?: boolean;
}

const MultipleImage: React.FC<MultipleImageProps> = ({
  choice,
  checked: isChecked = false,
  onChange,
  variant = "multiple",
  disabled = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (disabled) return;
    onChange?.(!isChecked, choice.label);
  };

  const getCheckboxIcon = () => {
    const color = isHovered
      ? "var(--mui-palette-options-active-color)"
      : "var(--mui-palette-options-default-color)";
    return variant === "single" ? (
      <CheckIconCircle color={color} />
    ) : (
      <CheckIcon color={color} />
    );
  };

  const getCheckedIcon = () => {
    const color = "var(--mui-palette-options-active-color)";
    return variant === "single" ? (
      <CheckFilledIconCircle color={color} />
    ) : (
      <CheckFilledIcon color={color} />
    );
  };

  const activeBorder = `1px solid ${
    isHovered || isChecked
      ? "var(--mui-palette-options-active-color)"
      : "var(--mui-palette-options-hover-background-color)"
  }`;

  return (
    <Box
      sx={{
        position: "relative",
        border: activeBorder,
        borderRadius: "8px",
        overflow: "hidden",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <Box
        sx={{
          p: "3.5px",
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 1,
          background: "white",
          borderRadius: "0px 0px 0px 6px",
          border: activeBorder,
          borderRight: "none",
          borderTop: "none",
          visibility: isChecked ? "visible" : "hidden",
        }}
      >
        <Checkbox
          sx={{ display: "flex", p: "0", height: "auto" }}
          checked={isChecked}
          icon={getCheckboxIcon()}
          checkedIcon={getCheckedIcon()}
        />
      </Box>
      <Box sx={{ position: "relative", aspectRatio: "1 / 1" }}>
        <Image
          src={choice.image || ""}
          alt={choice.label}
          fill
          style={{ objectFit: "cover" }}
        />
      </Box>
      <Box>
        <Typography
          variant="body1"
          sx={{
            py: 1.5,
            px: "16px",
            color: "#1b1e3d",
            fontSize: "14px",
          }}
        >
          {choice.label}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(MultipleImage);
