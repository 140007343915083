import { Box, Stack, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Layout } from "../shared/Layout";
import { ImageCarousel } from "./components";
import { CurrencyField } from "ui/CurrencyField";

export type ProductProps = {
  id: string;
  simulated?: boolean;
  images: string[];
  disabled?: boolean;
  onContinue: (value: string) => void;
  loading?: boolean;
  name?: string;
  description?: string;
  locals?: {
    language?: {
      language_code: string;
      name: string;
      native_name: "English";
      decimal: "." | ",";
      thousand: "." | ",";
      RTL: boolean;
    };
    currency?: {
      currency_code: string;
      symbol: string;
      precision: number;
    };
    country?: {
      name: string;
      default_language: string;
      default_currency: string;
      default_timezone: string;
      available_languages: string[];
    };
  };
  settings?: {
    countdown_timer: number;
    require_bid: boolean;
  };
  buttonLabel: string;
  inputPlaceholderLabel: string;
};

export const Product = ({
  id,
  simulated = false,
  images = [],
  name = "",
  description = "",
  onContinue,
  loading,
  disabled,
  locals = {},
  settings = {
    countdown_timer: 0,
    require_bid: false,
  },
  buttonLabel,
  inputPlaceholderLabel
}: ProductProps) => {
  const [carouselImages, setCarouselImages] = useState(images);

  const [answer, setAnswer] = useState<number | undefined>(undefined);
  const isSmallViewport = useMediaQuery("(max-width:600px)");
  const isSmallDevice = simulated || isSmallViewport;

  useEffect(() => {
    setCarouselImages(images);
  }, [images]);

  return (
    <Layout
      id={id}
      loading={loading}
      disabled={disabled || (!answer && settings?.require_bid && answer !== 0)}
      onContinue={() => {
        if (simulated) return;

        onContinue(answer?.toString() || "");
        setAnswer(undefined);
        setCarouselImages([]);
      }}
      countdown_timer={settings.countdown_timer}
      customFooter={() => (
        <CurrencyField
          settings={settings}
          symbol={locals?.currency?.symbol || "$"}
          precision={locals?.currency?.precision || 2}
          onChange={(value) => setAnswer(value)}
          placeholder={inputPlaceholderLabel}
        />
      )}
      customFooterContainerStyle={{
        ...(isSmallDevice
          ? {
              gap: 2,
              display: "flex",
              flexDirection: "column",
            }
          : {
              gap: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }),
      }}
      buttonLabel={buttonLabel || "Confirm"}
    >
      <Stack
        spacing="24px"
        sx={{
          width: "100%",
          py: {
            xs: "35px",
            sm: "0px",
            md: "0px",
          },
        }}
      >
        <Box>
          <ImageCarousel images={carouselImages} />
          {description && (
            <Box
              dangerouslySetInnerHTML={{ __html: description }}
              sx={{
                mx: { xs: "24px", sm: "0" },
                my: { xs: "24px", sm: "12px" },
              }}
            ></Box>
          )}
        </Box>
      </Stack>
    </Layout>
  );
};
