import type { Placement } from "@floating-ui/react";
import { Box, Backdrop as MuiBackdrop, Stack, type SxProps, TextField, type Theme, Typography } from "@mui/material";
import { type ReactElement, type ReactNode, useState } from "react";
import { SelectArrowIcon, SelectClearIcon, Tooltip } from "ui";
import { Menu, MenuItem, type MenuItemProps, type MenuProps } from "../Menu";

export const Select = ({
  value,
  placeholder,
  children,
  optionsWrapperStyles,
  styles,
  fieldStyles,
  backdrop,
  disabled,
  clearable,
  onClear,
  onOpen,
  onClose,
}: SelectProps) => {
  const [open, setOpen] = useState(false);

  const getMenuStyles = (placement: string, isOpen: boolean) => ({
    width: "100%",
    ...styles?.(placement as Placement, isOpen),
    ".select-field": {
      borderRadius: !isOpen
        ? "6px"
        : {
            "bottom-start": "6px 6px 0px 0px",
            "top-start": "0px 0px 6px 6px",
          }[placement] || "6px",
    },
  });

  return (
    <Menu
      styles={getMenuStyles}
      optionsWrapperStyles={optionsWrapperStyles}
      label={
        <SelectField
          open={open}
          value={value}
          placeholder={placeholder}
          fieldStyles={fieldStyles}
          clearable={clearable}
          onClear={onClear}
        />
      }
      backdrop={backdrop}
      disabled={disabled}
      optionsPlacement="bottom-start"
      onOpen={() => {
        setOpen(true);
        onOpen?.();
      }}
      onClose={() => {
        setOpen(false);
        onClose?.();
      }}
    >
      {children}
    </Menu>
  );
};

export const NestedOption = ({ children, optionsWrapperStyles, disabled, styles, ...rest }: NestedOptionProps) => {
  return (
    <Menu
      styles={(placement, open) => ({
        p: "8px 12px",
        cursor: "pointer",
        ":hover": {
          backgroundImage: "linear-gradient(to bottom, #f2f2f5, #eaeaf3)",
        },
        ...styles?.(placement, open),
      })}
      optionsWrapperStyles={(placement, isOpen) => ({
        maxHeight: "70vh",
        overflowY: "auto",
        ...optionsWrapperStyles?.(placement, isOpen),
      })}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Menu>
  );
};

export const Option = ({
  children,
  styles = {
    p: "8px",
  },
  preventCloseOnClick,
  disabled,
  onClick,
}: OptionProps) => {
  return (
    <MenuItem
      label={children}
      styles={{
        p: "8px",
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
        background: "#fff",
        borderRadius: "6px",
        ":hover": {
          backgroundImage: "linear-gradient(to bottom, #f2f2f5, #eaeaf3)",
        },
        ...styles,
      }}
      disabled={disabled}
      preventCloseOnClick={preventCloseOnClick}
      onItemClick={!disabled ? onClick : undefined}
    />
  );
};

export const SelectField = ({
  open,
  value,
  placeholder,
  fieldStyles,
  icon,
  clearable,
  onClear,
  onClick,
}: SelectFieldProps) => {
  const styles = value
    ? { color: "var(--mui-palette-input_fields-text-color)" }
    : { color: "var(--mui-palette-input_fields-placeholder-text-color)" };
  return (
    <>
      <Box
        sx={{
          px: "8px",
          height: "40px",
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          backgroundColor: "#fff",
          border: "solid 1px #ebebf0",
          justifyContent: "space-between",
          userSelect: "none",
          position: "relative",

          ...fieldStyles,
        }}
        className="select-field"
        onClick={onClick}
      >
        {typeof value === "string" || !value ? (
          <Typography
            className="select-field-value"
            sx={{
              fontSize: "14px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...styles,
            }}
          >
            {value || placeholder}
          </Typography>
        ) : (
          value
        )}

        {((clearable && !value) || !clearable) &&
          (icon || (
            <Box
              className="select-field-icon"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "24px",
              }}
            >
              <SelectArrowIcon open={open} w="24" />
            </Box>
          ))}
        {clearable && value && (
          <Box
            className="select-field-clear"
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              onClear?.();
            }}
          >
            <SelectClearIcon />
          </Box>
        )}
      </Box>
    </>
  );
};

type SelectFieldValueMulti = { type: "multiple"; items: { name?: string }[] };
type SelectFieldValueSingle = { type: "single"; name?: string };
type SelectFieldValueProps = (SelectFieldValueSingle | SelectFieldValueMulti) & {
  placeholder?: string;
  searchValue?: string;
  searchable?: boolean;
  autoFocus?: boolean;
  onRemove?: () => void;
  onSearch?: (value: string) => void;
};

export const SelectFieldValue = (props: SelectFieldValueProps) => {
  const handleRemoveMember = (e: any) => {
    if (e.key === "Backspace" && e.target?.value === "") {
      return props.onRemove?.();
    }
  };

  const value =
    props.type === "multiple" ? (
      props.items?.map((item) => <SelectedOption name={item?.name || ""} operator={false} />)
    ) : (
      <>{props?.name}</>
    );

  return (
    <Box
      sx={{
        flex: 1,
        py: "4px",
        overflowY: "scroll",
        scrollbarWidth: 0,
        scrollbarColor: "transparent transparent",
        maxHeight: "122px",

        gap: "5px",
        display: "flex",
        overflow: "auto",
        flexWrap: "wrap",
        alignItems: "center",
        alignSelf: "center",
        ml: props.type === "single" ? "4px" : "auto",
      }}
    >
      {value}

      {props.searchable && (
        <TextField
          autoFocus={props.autoFocus}
          sx={{
            width: "0",
            flexGrow: 1,
            minWidth: "30px",
            input: { padding: "4px 5px" },
            color: "var(--mui-palette-input_fields-text-color)",
            ".MuiInputBase-root": { border: "none", background: "none" },
          }}
          // disabled={disabled}
          value={props?.searchValue}
          autoComplete="off"
          placeholder={props?.placeholder || "Type or search"}
          onChange={(e) => props?.onSearch?.(e.target.value)}
          onKeyDown={handleRemoveMember}
        />
      )}
    </Box>
  );
};

// TODO: SHOULD BE REMOVED AND REPLACED WITH SelectFieldValue COMPONENT
export const SelectFieldMulti = ({
  items = [],
}: {
  items: { name: string }[];
}) => {
  if (items.length === 0) return null;

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        overflowY: "scroll",
        scrollbarWidth: 0,
        scrollbarColor: "transparent transparent",
      }}
    >
      <Stack direction="row" spacing="4px" maxWidth={100}>
        {items?.map(({ name }) => (
          <SelectedOption name={name} operator={false} />
        ))}
      </Stack>
    </Box>
  );
};

export const SelectedOption = ({
  name,
  operator,
  tooltip,
}: {
  name?: string;
  operator?: boolean;
  tooltip?: {
    title?: string;
    description?: string;
  };
}) => {
  const styles = operator
    ? { color: "#1b1e3d", border: "1px solid #ebebf0" }
    : { border: "1px solid rgba(98, 112, 193, 0.3)", background: "#f4f6ff" };

  const content = (
    <Box
      p="4.5px 8px"
      sx={{
        ...styles,
        borderRadius: "3px",
        display: "flex",
        width: "auto",
        minWidth: "min-content",
        maxWidth: "max-content",
        flex: "1 1 69px",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontSize: "14px",
          color: operator ? "#1b1e3d" : "#6260c1",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: "159px",
          overflow: "hidden",
        }}
      >
        {name}
      </Typography>
    </Box>
  );

  return tooltip ? (
    <Tooltip
      content={
        <Box sx={{ maxWidth: "268px" }}>
          <Typography variant="body1" color="#fff" fontSize="14px">
            {tooltip.title}
          </Typography>
          <Typography variant="body1" color="#C2C3CB" fontSize="13px">
            {tooltip.description}
          </Typography>
        </Box>
      }
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export const Backdrop = ({ visible, backdropStyles, backdropCustomStyles, backdropCustom, onClick }: BackdropProps) => {
  if (!visible) return null;

  return (
    <>
      {backdropCustom && <Box sx={backdropCustomStyles} onClick={() => onClick?.()} />}
      <MuiBackdrop
        open={visible}
        sx={{
          margin: "0 !important",
          zIndex: 10000,
          backgroundColor: "rgba(41, 44, 87, 0.2)",
          ":hover": { backgroundColor: "rgba(41, 44, 87, 0.2) !important" },
          ...backdropStyles,
        }}
        onClick={() => onClick?.()}
      />
    </>
  );
};

export type SelectProps = {
  value?: string | ReactNode | ReactElement | null;
  placeholder?: string;
  fieldStyles?: SxProps<Theme>;
  clearable?: boolean;
  onClear?: () => void;
} & Pick<
  MenuProps,
  "styles" | "backdrop" | "disabled" | "children" | "optionsPlacement" | "optionsWrapperStyles" | "onOpen" | "onClose"
>;

export type NestedOptionProps = {
  label: ReactNode | string;
  disabled?: boolean;
} & Pick<MenuProps, "children" | "optionsWrapperStyles" | "styles" | "optionsPlacement" | "preventCloseOnClick">;

export type OptionProps = {
  children: ReactNode;
  onClick?: (props: any) => void;
} & Pick<MenuItemProps, "preventCloseOnClick" | "styles" | "disabled">;

type SelectFieldProps = {
  open: boolean;
  icon?: ReactElement;
  onClick?: () => void;
  fieldStyles?: SxProps<Theme>;
  value?: string | ReactNode | ReactElement | null;
  placeholder?: string;
  clearable?: boolean;
  onClear?: () => void;
};

export type BackdropProps = {
  visible: boolean;
  onClick?: () => void;
  backdropStyles?: SxProps<Theme>;
  backdropCustomStyles?: SxProps<Theme>;
  backdropCustom?: boolean;
};
