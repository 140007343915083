import { VeylinxLogoIcon } from "ui";
import { Box, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        display: { xs: "none", sm: "flex" },
      }}
    >
      <Box
        data-testid="footer-box"
        display="flex"
        justifyContent="flex-end"
        px="18px"
        sx={{
          height: 48,
          width: "100%",
          boxShadow: "inset 0 1px 0 0 var(--mui-palette-layout-border-color)",
          backgroundColor: "var(--mui-palette-layout-background-color)",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          height="100%"
          borderLeft="1px solid var(--mui-palette-layout-border-color)"
          pl="8px"
        >
          <VeylinxLogoIcon w={32} h={32} />
          <Typography variant="body2" color="var(--mui-palette-layout-text-color)" ml="2px">
            Powered by Veylinx
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
