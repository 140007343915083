import {
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  type Placement,
  autoUpdate,
  flip,
  safePolygon,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from "@floating-ui/react";
import { Box, type SxProps, type Theme } from "@mui/material";
import {
  type ButtonHTMLAttributes,
  type Dispatch,
  type FocusEvent,
  type HTMLProps,
  type MouseEvent,
  type ReactNode,
  type SetStateAction,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Backdrop } from "../Select";

const MenuContext = createContext<{
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => Record<string, unknown>;
  activeIndex: number | null;
  setActiveIndex: Dispatch<SetStateAction<number | null>>;
  setHasFocusInside: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => {},
  setHasFocusInside: () => {},
  isOpen: false,
});

export const MenuItem = forwardRef<HTMLElement, MenuItemProps & ButtonHTMLAttributes<HTMLElement>>(
  (props, forwardedRef) => {
    const { label, styles, preventCloseOnClick, onItemClick, ...rest } = props;
    const menu = useContext(MenuContext);
    const item = useListItem({ label: label as any });
    const tree = useFloatingTree();
    const isActive = item.index === menu.activeIndex;

    const menuProps = {
      ...menu.getItemProps({
        onClick(event: MouseEvent<HTMLElement>) {
          if (!preventCloseOnClick) {
            rest.onClick?.(event);
            tree?.events.emit("click");
          }
          onItemClick?.(props);
        },
        onFocus(event: FocusEvent<HTMLElement>) {
          if (!preventCloseOnClick) {
            rest.onFocus?.(event);
            menu.setHasFocusInside(true);
          }
        },
      }),
    };

    return (
      <Box
        sx={{ outline: "none", ...styles }}
        {...props}
        ref={useMergeRefs([item.ref, forwardedRef])}
        role="menuitem"
        className="MenuItem"
        tabIndex={isActive ? 0 : -1}
        {...menuProps}
      >
        {label}
      </Box>
    );
  },
);

export const MenuComponent = forwardRef<HTMLElement, MenuProps>(
  (
    {
      children,
      label,
      styles,
      optionsWrapperStyles,
      backdrop,
      disabled,
      preventCloseOnClick,
      optionsPlacement,
      onOpen,
      onClose,
      ...props
    },
    forwardedRef,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasFocusInside, setHasFocusInside] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const elementsRef = useRef<Array<HTMLElement | null>>([]);
    const labelsRef = useRef<Array<string | null>>([]);
    const parent = useContext(MenuContext);

    const tree = useFloatingTree();
    const nodeId = useFloatingNodeId();
    const parentId = useFloatingParentNodeId();
    const item = useListItem();

    const isNested = parentId != null;

    const { floatingStyles, refs, context } = useFloating<HTMLElement>({
      nodeId,
      open: isOpen,
      onOpenChange: (open) => {
        if (!disabled) {
          setIsOpen(open);
          open ? onOpen?.() : onClose?.();
        }
      },
      placement: optionsPlacement ? optionsPlacement : isNested ? "right-start" : "bottom-start",
      middleware: [
        size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          },
        }),
        flip(),
        shift(),
      ],
      whileElementsMounted: autoUpdate,
    });

    const hover = useHover(context, {
      enabled: isNested,
      delay: { open: 75 },
      handleClose: safePolygon({ blockPointerEvents: true }),
    });
    const click = useClick(context, {
      event: "mousedown",
      toggle: !isNested,
      ignoreMouse: isNested,
    });
    const role = useRole(context, { role: "menu" });
    const dismiss = useDismiss(context, { bubbles: true });
    const listNavigation = useListNavigation(context, {
      listRef: elementsRef,
      activeIndex,
      nested: isNested,
      onNavigate: setActiveIndex,
    });
    const typeahead = useTypeahead(context, {
      listRef: labelsRef,
      onMatch: isOpen ? setActiveIndex : undefined,
      activeIndex,
    });

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
      hover,
      click,
      role,
      dismiss,
      listNavigation,
      typeahead,
    ]);

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.
    useEffect(() => {
      if (!tree) return;

      function handleTreeClick() {
        if (!preventCloseOnClick) {
          setIsOpen(false);
          onClose?.();
        }
      }

      function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
        if (event.nodeId !== nodeId && event.parentId === parentId && !preventCloseOnClick) {
          setIsOpen(false);
          onClose?.();
        }
      }

      tree.events.on("click", handleTreeClick);
      tree.events.on("menuopen", onSubMenuOpen);

      return () => {
        tree.events.off("click", handleTreeClick);
        tree.events.off("menuopen", onSubMenuOpen);
      };
    }, [tree, nodeId, parentId]);

    useEffect(() => {
      if (isOpen && tree) {
        tree.events.emit("menuopen", { parentId, nodeId });
      }
    }, [tree, isOpen, nodeId, parentId]);

    return (
      <>
        <FloatingNode id={nodeId}>
          <Box
            sx={{
              outline: "none",
              position: "relative",
              zIndex: isOpen ? 10001 : 1,
              ...styles?.(context.placement, isOpen),
            }}
            ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
            tabIndex={!isNested ? undefined : parent.activeIndex === item.index ? 0 : -1}
            role={isNested ? "menuitem" : undefined}
            data-open={isOpen ? "" : undefined}
            data-nested={isNested ? "" : undefined}
            data-focus-inside={hasFocusInside ? "" : undefined}
            className={isNested ? "MenuItem" : "RootMenu"}
            {...getReferenceProps(
              parent.getItemProps({
                ...props,
                onFocus(event: FocusEvent<HTMLElement>) {
                  props.onFocus?.(event);
                  setHasFocusInside(false);
                  parent.setHasFocusInside(true);
                },
              }),
            )}
          >
            {label}
          </Box>
          <MenuContext.Provider
            value={{
              activeIndex,
              setActiveIndex,
              getItemProps,
              setHasFocusInside,
              isOpen,
            }}
          >
            <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
              {isOpen && !disabled && (
                <FloatingPortal>
                  <FloatingFocusManager
                    context={context}
                    modal={false}
                    initialFocus={isNested ? -1 : 0}
                    returnFocus={!isNested}
                  >
                    <Box
                      ref={refs.setFloating}
                      className="Menu"
                      sx={{
                        ...floatingStyles,
                        outline: "none",
                        zIndex: isOpen ? 10001 : 1,
                        ...optionsWrapperStyles?.(context.placement, isOpen),
                      }}
                      {...getFloatingProps()}
                      // Default onKeyDown:
                      onKeyDown={() => null}
                    >
                      {children}
                    </Box>
                  </FloatingFocusManager>
                </FloatingPortal>
              )}
            </FloatingList>
          </MenuContext.Provider>
        </FloatingNode>
        {backdrop && <Backdrop visible={isOpen} />}
      </>
    );
  },
);

export const Menu = forwardRef<HTMLElement, MenuProps>((props, ref) => {
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <MenuComponent {...props} ref={ref} />
      </FloatingTree>
    );
  }

  return <MenuComponent {...props} ref={ref} />;
});

Menu.displayName = "Menu";
MenuComponent.displayName = "MenuComponent";
MenuItem.displayName = "MenuItem";

export interface MenuProps extends Omit<HTMLProps<HTMLElement>, "label"> {
  label: ReactNode | string;
  nested?: boolean;
  children?: ReactNode;
  styles?: (placement: Placement, isOpen: boolean) => SxProps<Theme>;
  optionsWrapperStyles?: (placement: Placement, isOpen: boolean) => SxProps<Theme>;
  backdrop?: boolean;
  disabled?: boolean;
  preventCloseOnClick?: boolean;
  optionsPlacement?: Placement;
  onOpen?: () => void;
  onClose?: () => void;
}

export interface MenuItemProps {
  styles?: SxProps<Theme>;
  label: ReactNode | string;
  disabled?: boolean;
  preventCloseOnClick?: boolean;
  onItemClick?: (props: MenuItemProps) => void;
}
