import {
  Box,
  Button,
  CircularProgress,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

export const Layout = ({
  id,
  children,
  title,
  onContinue,
  disabled,
  loading,
  buttonLabel = "Continue",
  start_countdown = true,
  auto_proceed_timer = 0,
  countdown_timer = 0,
  sx = {},
  titleSx = {},
  childrenSx = {},
  customFooter,
  customFooterContainerStyle = {},
  buttonContainerStyle,
}: {
  id?: string;
  children?: ReactNode;
  title?: string;
  onContinue: () => void;
  disabled?: boolean;
  loading?: boolean;
  buttonLabel?: string;
  start_countdown?: boolean;
  auto_proceed_timer?: number;
  countdown_timer?: number;
  sx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  childrenSx?: SxProps<Theme>;
  customFooter?: () => ReactNode;
  customFooterContainerStyle?: SxProps<Theme>;
  buttonContainerStyle?: any;
}) => {
  const [state, secondsLeft] = useCountDownTimer(
    start_countdown,
    id || "",
    countdown_timer || 0,
    auto_proceed_timer || 0,
  );

  useEffect(() => {
    if (auto_proceed_timer && auto_proceed_timer > 0 && start_countdown) {
      const timer = setTimeout(() => {
        onContinue();
      }, (auto_proceed_timer + countdown_timer) * 1000);
      return () => clearTimeout(timer);
    }
  }, [auto_proceed_timer, countdown_timer, onContinue, start_countdown]);

  return (
    <Box width="100%" height="100%" sx={sx}>
      <Box
        height="100%"
        width="100%"
        display="flex"
        flexDirection={"column"}
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: {
            xs: "space-between",
            sm: "center",
            md: "center",
          },
        }}
      >
        <Box
          sx={{
            overflowY: "auto",
          }}
        >
          {title && (
            <Box
              sx={{
                py: { xs: "32px", sm: "0" },
                px: { xs: "24px", sm: "0" },
                color: "var(--mui-palette-layout-text-color)",
                a: {
                  color: "var(--mui-palette-options-active-color)",
                },
                ...titleSx,
              }}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}

          <Stack
            spacing="8px"
            sx={{
              flex: {
                xs: 1,
                sm: "initial",
                md: "initial",
              },
              ...childrenSx,
            }}
          >
            {children}
          </Stack>
        </Box>

        <Box
          sx={{
            flex: {
              xs: 0.04,
              sm: 0.04,
              md: "initial",
            },
            ...(customFooter && customFooterContainerStyle),
            p: { xs: "24px", sm: "24px 0 24px 0" },
            borderTop: {
              xs: "1px solid var(--mui-palette-layout-border-color)",
              sm: "none",
            },
            backgroundColor: "var(--mui-palette-layout-background-color)",
            ...(buttonContainerStyle || {}),
          }}
        >
          {customFooter && customFooter()}
          <Button
            variant="contained"
            onClick={onContinue}
            sx={{
              padding: "11.8px 30.2px",
              width: { xs: "100%", sm: "auto" },
              height: { xs: "48px", sm: "auto" },
              minWidth: "120px",
              overflow: "hidden",
            }}
            disabled={disabled || state?.disabled}
          >
            {state?.showCountdown && !loading && <span>{`${secondsLeft}s`}</span>}
            {(!state?.showCountdown || loading) &&
              (loading ? (
                <CircularProgress
                  size={18}
                />
              ) : (
                <span>{buttonLabel}</span>
              ))}
            {state?.disabled && (
              <Box
                sx={{
                  height: "100%",
                  backgroundImage:
                    "linear-gradient(to bottom, var(--mui-palette-buttons-background-color-80), var(--mui-palette-buttons-background-color-100))",
                  width: `${Math.min(
                    100,
                    100 -
                    ((secondsLeft - auto_proceed_timer) /
                      (countdown_timer || 0)) *
                    100,
                  )}%`,
                  borderRightWidth: 1,
                  borderRightStyle: "solid",
                  borderRightColor: "var(--mui-palette-layout-border-color)",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: -1,
                  transition: "width 1s linear",
                }}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

function useCountDownTimer(
  start_countdown: boolean,
  id: string,
  seconds: number,
  additionalSeconds: number,
): [
    {
      disabled: boolean;
      showCountdown: boolean;
    },
    number,
  ] {
  const [disabled, setDisabled] = useState(seconds > 0);
  const [showCountdown, setShowCountdown] = useState(
    seconds + additionalSeconds > 0,
  );
  const [secondsLeft, setSecondsLeft] = useState(seconds + additionalSeconds);
  useEffect(() => {
    if (seconds + additionalSeconds > 0) {
      setSecondsLeft(seconds + additionalSeconds);
    }
  }, [id]);

  useEffect(() => {
    if (start_countdown) {
      if (secondsLeft > 0) {
        if (secondsLeft + 1 - additionalSeconds <= 0) {
          setDisabled(false);
        }
        setShowCountdown(true);
        setDisabled(true);

        const timer = setTimeout(() => {
          setSecondsLeft(secondsLeft - 1);
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        setShowCountdown(false);
        setDisabled(false);
      }
    }
  }, [secondsLeft, additionalSeconds, id, start_countdown]);

  return [
    {
      disabled,
      showCountdown,
    },
    secondsLeft,
  ];
}
