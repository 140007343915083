"use client";
import { Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { ProductProps } from "../steps/product";

export const CurrencyField = ({
  defaultValue,
  hideSymbol = false,
  symbol = "$",
  placeholder = "Enter your bid",
  inputProps = {},
  precision = 2,
  onChange,
  onBlur,
  cents = false,
  settings,
  style = {},
  height,
  label,
}: {
  defaultValue?: number | null;
  symbol?: string;
  hideSymbol?: boolean;
  placeholder?: string;
  inputProps?: any;
  onChange?: (value?: number) => void;
  onBlur?: (value?: any) => void;
  precision?: number;
  cents?: boolean;
  settings?: ProductProps["settings"];
  style?: React.CSSProperties;
  height?: number | string;
  label?: string;
}) => {
  const [value, setValue] = useState<number | null | undefined | string>(
    defaultValue,
  );

  useEffect(() => {
    return () => {
      setValue("");
    };
  }, [settings]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: height || "40px",
        minWidth: "72px",
        width: "100%",
        ...style,
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
          ".MuiFormControl-root": { width: "100%" },
        }}
      >
        <NumericFormat
          label={label}
          value={value}
          decimalSeparator="."
          allowedDecimalSeparators={[","]}
          decimalScale={precision}
          allowLeadingZeros={false}
          allowNegative={false}
          onValueChange={({ floatValue }) => {
            onChange &&
              onChange(cents && floatValue ? floatValue * 100 : floatValue);
            setValue(floatValue);
          }}
          onBlur={() => {
            onBlur &&
              onBlur(
                cents && typeof Number(value) === "number"
                  ? Number(value) * 100
                  : value,
              );
          }}
          customInput={TextField}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          sx={{
            // remove default up and down arrows
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              { webkitAppearance: "none", margin: 0 },
            ".MuiInputBase-root": {
              overflow: "hidden",
              height: height || "40px",
            },
            "& input": {
              // backgroundColor: "#fcfcfd",
              marginLeft: {
                xs: symbol && !hideSymbol ? "48px" : "0",
                sm: symbol && !hideSymbol ? "38px" : "0",
              },
            },
            ".MuiOutlinedInput-root": {
              height: {
                xs: height || "48px",
                sm: height || "40px",
              },
            },

            ".MuiFormLabel-root": {
              color: "#494b64",
              top: "3px",
              background: "#fdfdfe",
              lineHeight: "normal",
              "&.Mui-focused ": { color: "#494b64" },
            },
          }}
          InputProps={{
            inputProps: {
              inputmode: "decimal",
              min: 0,
              autoComplete: "off",
            },
            startAdornment: symbol && !hideSymbol && (
              <Box
                sx={{
                  userSelect: "none",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  left: "0",
                  padding: "10px",
                  borderRight:
                    "1px solid var(--mui-palette-layout-border-color)",
                  borderRadius: "4px",
                  minWidth: { xs: "48px", sm: "40px" },
                }}
              >
                {symbol}
              </Box>
            ),
          }}
          {...inputProps}
        />
      </Box>
    </Box>
  );
};
