import { Box, Stack } from "@mui/material";
import NextImage from "next/image";
import { useCallback, useEffect, useRef } from "react";
import { Layout } from "../shared/Layout";

type ImageProps = {
  simulated?: boolean;
  src: string;
  alt: string;
  buttonLabel: string;
  disabled?: boolean;
  onContinue: () => void;
  loading?: boolean;
  description?: string;
  settings?: {
    auto_proceed_timer: number;
    countdown_timer: number;
  };
};

export const Image = ({
  src,
  alt,
  buttonLabel = "Next",
  disabled,
  onContinue,
  loading,
  description,
  settings = {
    auto_proceed_timer: 0,
    countdown_timer: 0,
  },
}: ImageProps) => {
  const imageRef = useRef<HTMLImageElement | null>(null);

  const handleResize = useCallback(() => {
    if (imageRef?.current?.parentElement && imageRef.current?.height) {
      imageRef.current.parentElement.style.height = `${imageRef.current.height}px`;
    }
  }, [imageRef]);

  useEffect(() => {
    // set a window resize listener
    window.addEventListener("resize", handleResize);
    // cleanup function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Layout
      buttonLabel={buttonLabel}
      disabled={disabled}
      onContinue={onContinue}
      loading={loading}
      auto_proceed_timer={settings.auto_proceed_timer}
      countdown_timer={settings.countdown_timer}
    >
      <Stack
        spacing="24px"
        sx={{
          width: "100%",
          py: {
            xs: "35px",
            sm: "0px",
            md: "0px",
          },
        }}
      >
        <Box
          sx={{
            height: "400px",
            display: "flex",
            overflow: "hidden",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: { xs: "0px", sm: "6px" },
            "> img": {
              height: "auto !important",
            },
          }}
        >
          <NextImage
            ref={imageRef}
            src={src}
            alt={alt}
            onLoad={handleResize}
            layout="fill"
            objectFit="contain"
          />
        </Box>
        {description && (
          <Box
            dangerouslySetInnerHTML={{ __html: description }}
            sx={{
              py: { xs: "24px", sm: "0" },
              px: { xs: "24px", sm: "0" },
            }}
          />
        )}
      </Stack>
    </Layout>
  );
};
