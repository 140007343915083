import React from "react";

export const Loader = ({ label = "Loading..." }: { label?: string }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 96px)",
      }}
    >
      {label}
    </div>
  );
};

export const EmptyLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 96px)",
      }}
    />
  );
};
