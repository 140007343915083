import {
  Box,
  BoxProps,
  ClickAwayListener,
  Popper as MuiPopper,
  PopperPlacementType,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { uniqueId } from "lodash";
import React, { MouseEvent, useRef, useState } from "react";
import { DatePickerProps } from "ui/DatePicker";
import { Backdrop, BackdropProps } from "../../../apps/web/src/components/Select";

export const Dropdown = ({
  input,
  inputRootProps,
  children,
  backdropStyles,
  hideBackdrop,
  backdropCustom,
  backdropCustomStyles = {},
  label,
  labelProps = {},
  direction = "row",
  popperProps = {},
  hideLabelOnOpen,
  onBackdropClick = () => {},
  onClosed,
  onOpened,
}: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    onBackdropClick?.();
    onClosed?.();
  };

  const handleSelectFieldClick = () => {
    if (!popperProps?.disabled) {
      setOpen((prevOpen) => {
        const shouldClose = prevOpen;
        const callback = shouldClose ? onClosed : onOpened;
        callback?.();
        return !shouldClose;
      });
    }
  };

  const handleMainClick = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    handleSelectFieldClick();
    inputRootProps?.onClick?.(event);
  };

  const showLabel = label && !open && hideLabelOnOpen;
  return (
    <Box
      ref={wrapperRef}
      sx={{ display: "flex", flexDirection: direction, alignItems: "center" }}
    >
      {showLabel && (
        <Typography variant="body1" {...labelProps}>
          {label}
        </Typography>
      )}
      <Popper
        {...popperProps}
        placement="bottom"
        popperRootProps={{ sx: { width: "100%" } }}
        main={
          <Box {...inputRootProps} onClick={handleMainClick}>
            {input(open)}
          </Box>
        }
        mainSx={(placement) => ({
          ".select-field": {
            backgroundColor: "var(--mui-palette-input_fields-background-color)",
            border: "1px solid var(--mui-palette-layout-border-color)",
            borderRadius: open
              ? placement === "top"
                ? "0 0 6px 6px"
                : "6px 6px 0 0"
              : "6px",
          },
        })}
        contentSx={(placement) => ({
          ".popper-content": {
            border: "1px solid #f2f2f5",
            borderBottom: placement === "top" ? "none" : "1px solid #f2f2f5",
            borderTop: placement === "bottom" ? "none" : "1px solid #f2f2f5",
            width: (wrapperRef?.current as any)?.clientWidth,
            borderRadius:
              placement === "bottom" ? "0 0 6px 6px" : "6px 6px 0 0",
            overflow: "hidden",
            "  > div ": {
              borderBottom: placement === "top" ? "none" : "auto",
              borderTop: placement === "bottom" ? "none" : "auto",
            },
          },
        })}
        onClose={handleClose}
      >
        {children}
      </Popper>
      <Backdrop
        backdropStyles={backdropStyles}
        visible={open && !hideBackdrop}
        backdropCustom={!!backdropCustom}
        onClick={handleClose}
        backdropCustomStyles={backdropCustomStyles}
      />
    </Box>
  );
};

export const Popper = ({
  main,
  interaction = "click",
  placement = "bottom-start",
  hideOnSelect,
  hideOnClickOutside = true,
  fullWidth = false,
  disabled,
  children,
  contentSx,
  popperRootProps = {},
  mainSx,
  onOpen,
  onClose,
}: PopperProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const mainRef = useRef(null);
  const childrenRef = useRef(null);
  const [popperRef, setPopperRef] = useState<any>(null);
  const handleOpen = (value: (EventTarget & HTMLDivElement) | null) => {
    setAnchorEl(value);
    value ? onOpen?.() : onClose?.();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLElement>) => {
    // check first if e.relatedTarget an element:
    if (e.relatedTarget instanceof Element) {
      const isLeaving =
        !(mainRef.current as any)?.contains(e.relatedTarget) &&
        !(childrenRef.current as any)?.contains(e.relatedTarget);

      if (isLeaving && interaction === "hover") {
        handleClose();
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = uniqueId();
  const popperId = open ? id : undefined;
  const placementType = popperRef?.getAttribute("data-popper-placement");

  return (
    <Box {...popperRootProps}>
      <Box
        ref={mainRef}
        aria-describedby={popperId}
        onClick={(e) => {
          if (interaction === "click" && !disabled) {
            handleOpen(anchorEl ? null : e.currentTarget);
          }
        }}
        onMouseEnter={(e) => {
          if (interaction === "hover") {
            handleOpen(e.currentTarget);
          }
        }}
        onMouseLeave={handleMouseLeave}
        sx={{
          position: "relative",
          zIndex: open ? 10001 : 0,
          ...mainSx?.(placementType),
        }}
      >
        {main}
      </Box>

      <MuiPopper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        ref={setPopperRef}
        sx={{ zIndex: 10001, ...contentSx?.(placementType) }}
      >
        <ClickAwayListener
          onClickAway={(e) => {
            if (
              !(mainRef.current as any)?.contains(e.target) &&
              hideOnClickOutside
            ) {
              handleClose();
            }
          }}
        >
          <Box
            ref={childrenRef}
            className="popper-content"
            onMouseLeave={handleMouseLeave}
            onClick={(e) => {
              const isLeavingElement = !(childrenRef.current as any)?.contains(
                e.target,
              ); // for nested poppers
              if (hideOnSelect || (isLeavingElement && hideOnClickOutside)) {
                handleClose();
              }
            }}
            sx={{
              width: fullWidth ? anchorEl?.clientWidth : "auto",
            }}
          >
            {children}
          </Box>
        </ClickAwayListener>
      </MuiPopper>
    </Box>
  );
};

type DropdownProps = {
  input: (isOpen: boolean) => React.ReactNode;
  inputRootProps?: BoxProps;
  children: React.ReactNode;
  onClosed?: any; // TODO: fix this
  onOpened?: any; // TODO: fix this
  popperProps?: any; // TODO: fix this
  hideBackdrop?: boolean; // TODO: fix this
  onBackdropClick?: () => void; // TODO: fix this
} & Pick<
  BackdropProps,
  "backdropStyles" | "backdropCustom" | "backdropCustomStyles"
> &
  Pick<
    DatePickerProps,
    "label" | "labelProps" | "direction" | "hideLabelOnOpen"
  >;

export interface PopperProps {
  id?: string;
  main: React.ReactNode;
  interaction?: "click" | "hover";
  placement?: PopperPlacementType;
  hideOnSelect?: boolean;
  hideOnClickOutside?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  children: React.ReactNode;
  contentSx?: (placement: PopperPlacementType) => SxProps<Theme>;
  mainSx?: (placement: PopperPlacementType) => SxProps<Theme>;
  popperRootProps?: BoxProps;
  onOpen?: () => void;
  onClose?: () => void;
}

{
  /* <Popper 
    main={<Box sx={{ border: "1px solid blue" }}>Parent</Box>}
    placement="bottom-start"
    interaction="click">
    <Popper
        main={<Box sx={{ border: "1px solid blue" }}>Child</Box>}
        placement="left-start"
        interaction="hover"
        hideOnSelect>
            <Box sx={{ width: "100px", border: "1px solid red" }}>
                Grand child
            </Box>
    </Popper>
</Popper> */
}
