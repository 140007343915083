import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
  SxProps,
} from "@mui/material";
import React, { CSSProperties, ReactElement, useState } from "react";
import { MenuCloseIcon, MenuIcon } from "./Icons";
import { Tooltip } from "./Tooltip";
import Link from "next/link";

export interface MenuType {
  id?: string | number;
  label: string;
  icon?: ReactElement;
  href?: string;
  tooltip?: {
    visible?: boolean;
    content: ReactElement;
  };
  disabled?: boolean;
  onClick?: () => void;
  hidden?: boolean;
}

export interface MenuProps {
  sx?: SxProps;
  items?: MenuType[];
  position?: "left" | "right";
  menuItemStyles?: SxProps;
  onClose?: () => void;
  renderButton?: (open?: boolean) => React.ReactElement;
  iconButtonStyles?: SxProps;
  papperSx?: CSSProperties;
  disabled?: boolean;
  content?: ReactElement;
  disableRipple?: boolean;
}
export const Menu: React.FC<MenuProps> = ({
  sx = {},
  items,
  disableRipple = false,
  content,
  position = "right",
  menuItemStyles = {},
  onClose,
  renderButton,
  iconButtonStyles = {},
  papperSx = {},
  disabled,
}: MenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <Box
      sx={sx}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {renderButton ? (
        <Box
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? "not-allowed" : "pointer",
          }}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            if (!disabled) {
              e.stopPropagation();
              e.preventDefault();
              setAnchorEl(e.currentTarget);
            }
          }}
        >
          {renderButton(open)}
        </Box>
      ) : (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            if (!disabled) {
              e.stopPropagation();
              e.preventDefault();
              setAnchorEl(e.currentTarget);
            }
          }}
          disableRipple={disableRipple}
          sx={{
            padding: "4px",
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? "not-allowed" : "pointer",
            ...iconButtonStyles,
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <MuiMenu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: position === "left" ? "right" : "left",
        }}
        id="long-menu"
        MenuListProps={{ "aria-labelledby": "long-button" }}
        anchorEl={anchorEl}
        open={open}
        onClose={(e: React.SyntheticEvent<Element, Event>) => {
          setAnchorEl(null);
          onClose?.();
          e.stopPropagation();
          e.preventDefault();
        }}
        PaperProps={{
          style: {
            margin: "-17px 0px 0px 14px",
            maxHeight: 300 * 4.5,
            overflow: "initial",
            width: "188px",
            ...papperSx,
          },
        }}
      >
        <Box
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(null);
            onClose?.();
            e.stopPropagation();
            e.preventDefault();
          }}
          sx={{
            background: "linear-gradient(180deg,#5b6379,#464c5e)",
            display: "flex",
            borderRadius: "50%",
            width: "24px",
            height: "24px",
            position: "absolute",
            top: "-11px",
            [position === "left" ? "right" : "left"]: "-10px",
            zIndex: 1,
            cursor: "pointer",
          }}
        >
          <MenuCloseIcon color="white" />
        </Box>
        {items ? (
          <MenuItems
            items={items}
            setAnchorEl={setAnchorEl}
            menuItemStyles={menuItemStyles}
          />
        ) : (
          content || <></>
        )}
      </MuiMenu>
    </Box>
  );
};

const MenuItems = ({
  items,
  setAnchorEl,
  menuItemStyles = {},
}: {
  items: MenuType[];
  setAnchorEl: any;
  menuItemStyles?: SxProps;
}) => {
  return (
    <>
      {items.map(
        ({ id, label, icon, href, tooltip, disabled, onClick }, index) => {
          const content = (
            <MenuItem
              key={index}
              onClick={(e) => {
                if (!disabled) {
                  e.stopPropagation();
                  // e.preventDefault();
                  onClick?.();
                  setAnchorEl(null);
                }
              }}
              sx={{
                ...menuItemStyles,
                opacity: disabled ? 0.5 : 1,
                cursor: disabled ? "not-allowed" : "pointer",
              }}
            >
              {icon && (
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                    minWidth: "24px",
                    borderRadius: "3px",
                    marginRight: "8px",
                    backgroundImage:
                      "linear-gradient(to bottom, #f8f8fa 0%, #ebebf3)",
                  }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                </Box>
              )}
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          );

          const contentWithTooltipCheck = tooltip?.visible ? (
            <Tooltip key={id} content={<Box>{tooltip?.content}</Box>}>
              {content}
            </Tooltip>
          ) : (
            content
          );

          return disabled || !href ? (
            contentWithTooltipCheck
          ) : (
            <Link shallow key={id} href={href}>
              {contentWithTooltipCheck}
            </Link>
          );
        },
      )}
    </>
  );
};
