"use client";
import { styled } from "@mui/system";
import { Avatar, Box, FormHelperText } from "@mui/material";
import { IoIosAdd } from "react-icons/io";
import { ComponentShield } from "./ComponentShield";
import { AvatarIcon } from "./Icons";
import { ReactElement } from "react";

interface UploadAvatarProps {
  helperText?: string;
  src?: string;
  avatarPlaceholder?: ReactElement;
  imageStyles?: React.CSSProperties;
  onUpload?: (e: React.ChangeEvent<HTMLInputElement>) => void | Promise<void>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  loading?: boolean;
}

export const UploadAvatar: React.FC<UploadAvatarProps> = ({
  helperText,
  disabled,
  onUpload,
  src,
  imageStyles = {},
  avatarPlaceholder = <AvatarIcon w={40} h={40} />,
  loading,
}) => {
  return (
    <SBox data-testid="upload-avatar">
      <Box
        sx={{
          position: "relative",
          width: "auto",
          minWidth: "min-content",
          maxWidth: "max-content",
        }}
      >
        {src ? (
          <Avatar
            src={src}
            alt="profile-image"
            sx={{
              width: 70,
              height: 70,
              marginBottom: 1.1,
              opacity: loading || disabled ? 0.5 : 1,
              ...imageStyles,
            }}
          />
        ) : (
          <AvatarPlaceholder sx={imageStyles}>
            {avatarPlaceholder}
          </AvatarPlaceholder>
        )}

        <ComponentShield hasPermission={!disabled}>
          <div className="upload-icon">
            <input
              type="file"
              id="fileElem"
              name="image"
              accept="image/*"
              onChange={onUpload}
            />
            <label htmlFor="fileElem">
              <IoIosAdd size={24} color="white" />
            </label>
          </div>
        </ComponentShield>
      </Box>

      <FormHelperText>{helperText}</FormHelperText>
    </SBox>
  );
};

export const AvatarPlaceholder: any = styled(Box)`
  width: 70px;
  height: 70px;
  border-radius: 35px;
  border: solid 1px #ebebf0;
  background-color: #fcfcfd;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SBox = styled(Box)`
  .upload-icon {
    position: absolute;
    top: 50%;
    right: -13px;
    transform: translateY(-50%);
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #94cbae -12%, #77b994 88%);
    input {
      display: none;
    }
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
`;
